.igcse__container .igcse__image {
    margin-bottom: 4rem;
}

.igcse__container div:not(:first-child) {
    background: var(--color-bg1);
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
}
.igcse__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.igcse__container div p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.igcse__heading h1 {
    text-align: center;
}
/* ========== Media Queries (Tablet) ============ */
@media screen and (max-width: 820px) {
    .igcse {
        margin-top: -18rem;
    }
    .igcse__container {
        width: var(--container-width-md);                
    }

    .igcse__container div{        
        width: 45rem;        
    }
    .igcse__heading {
        width: 30rem;
        margin-top: 20rem;        
    }  
}

/* ========== Media Queries (Phone) ============ */
@media screen and (max-width: 430px) {
    .igcse__container {
        width: var(--container-width-sm);        
    }

    .igcse__container div{        
        width: 22rem;          
    }

    .igcse__container div p {
        text-align: left;
        font-size: 1rem;
    }
}