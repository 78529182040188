.night {
    position: relative;
    width: 100%;
    height: 100%;
    transform: rotateZ(45deg);
}

.shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px #699bff);
    -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
    animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}

.shooting_star::before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
    animation: shining 3000ms ease-in-out infinite;
}

.shooting_star::after {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    -webkit-animation: shining 3000ms ease-in-out infinite;
    animation: shining 3000ms ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
}

.shooting_star:nth-child(1) {
    top: calc(50% - -59px);
    left: calc(50% - 112px);
    -webkit-animation-delay: 8552ms;
    animation-delay: 8552ms;
}

.shooting_star:nth-child(1)::before,
.shooting_star:nth-child(1)::after {
    -webkit-animation-delay: 8552ms;
    animation-delay: 8552ms;
}

.shooting_star:nth-child(2) {
    top: calc(50% - 47px);
    left: calc(50% - 64px);
    -webkit-animation-delay: 7369ms;
    animation-delay: 7369ms;
}

.shooting_star:nth-child(2)::before,
.shooting_star:nth-child(2)::after {
    -webkit-animation-delay: 7369ms;
    animation-delay: 7369ms;
}

.shooting_star:nth-child(3) {
    top: calc(50% - 124px);
    left: calc(50% - 113px);
    -webkit-animation-delay: 5662ms;
    animation-delay: 5662ms;
}

.shooting_star:nth-child(3)::before,
.shooting_star:nth-child(3)::after {
    -webkit-animation-delay: 5662ms;
    animation-delay: 5662ms;
}

.shooting_star:nth-child(4) {
    top: calc(50% - -45px);
    left: calc(50% - 183px);
    -webkit-animation-delay: 3858ms;
    animation-delay: 3858ms;
}

.shooting_star:nth-child(4)::before,
.shooting_star:nth-child(4)::after {
    -webkit-animation-delay: 3858ms;
    animation-delay: 3858ms;
}

.shooting_star:nth-child(5) {
    top: calc(50% - 4px);
    left: calc(50% - 128px);
    -webkit-animation-delay: 3547ms;
    animation-delay: 3547ms;
}

.shooting_star:nth-child(5)::before,
.shooting_star:nth-child(5)::after {
    -webkit-animation-delay: 3547ms;
    animation-delay: 3547ms;
}

.shooting_star:nth-child(6) {
    top: calc(50% - 23px);
    left: calc(50% - 148px);
    -webkit-animation-delay: 2395ms;
    animation-delay: 2395ms;
}

.shooting_star:nth-child(6)::before,
.shooting_star:nth-child(6)::after {
    -webkit-animation-delay: 2395ms;
    animation-delay: 2395ms;
}

.shooting_star:nth-child(7) {
    top: calc(50% - -192px);
    left: calc(50% - 250px);
    -webkit-animation-delay: 9203ms;
    animation-delay: 9203ms;
}

.shooting_star:nth-child(7)::before,
.shooting_star:nth-child(7)::after {
    -webkit-animation-delay: 9203ms;
    animation-delay: 9203ms;
}

.shooting_star:nth-child(8) {
    top: calc(50% - 188px);
    left: calc(50% - 191px);
    -webkit-animation-delay: 3990ms;
    animation-delay: 3990ms;
}

.shooting_star:nth-child(8)::before,
.shooting_star:nth-child(8)::after {
    -webkit-animation-delay: 3990ms;
    animation-delay: 3990ms;
}

.shooting_star:nth-child(9) {
    top: calc(50% - 62px);
    left: calc(50% - 17px);
    -webkit-animation-delay: 5796ms;
    animation-delay: 5796ms;
}

.shooting_star:nth-child(9)::before,
.shooting_star:nth-child(9)::after {
    -webkit-animation-delay: 5796ms;
    animation-delay: 5796ms;
}

.shooting_star:nth-child(10) {
    top: calc(50% - 57px);
    left: calc(50% - 55px);
    -webkit-animation-delay: 8163ms;
    animation-delay: 8163ms;
}

.shooting_star:nth-child(10)::before,
.shooting_star:nth-child(10)::after {
    -webkit-animation-delay: 8163ms;
    animation-delay: 8163ms;
}

.shooting_star:nth-child(11) {
    top: calc(50% - -31px);
    left: calc(50% - 131px);
    -webkit-animation-delay: 2675ms;
    animation-delay: 2675ms;
}

.shooting_star:nth-child(11)::before,
.shooting_star:nth-child(11)::after {
    -webkit-animation-delay: 2675ms;
    animation-delay: 2675ms;
}

.shooting_star:nth-child(12) {
    top: calc(50% - 42px);
    left: calc(50% - 115px);
    -webkit-animation-delay: 9097ms;
    animation-delay: 9097ms;
}

.shooting_star:nth-child(12)::before,
.shooting_star:nth-child(12)::after {
    -webkit-animation-delay: 9097ms;
    animation-delay: 9097ms;
}

.shooting_star:nth-child(13) {
    top: calc(50% - -19px);
    left: calc(50% - 156px);
    -webkit-animation-delay: 9728ms;
    animation-delay: 9728ms;
}

.shooting_star:nth-child(13)::before,
.shooting_star:nth-child(13)::after {
    -webkit-animation-delay: 9728ms;
    animation-delay: 9728ms;
}

.shooting_star:nth-child(14) {
    top: calc(50% - -108px);
    left: calc(50% - 103px);
    -webkit-animation-delay: 4079ms;
    animation-delay: 4079ms;
}

.shooting_star:nth-child(14)::before,
.shooting_star:nth-child(14)::after {
    -webkit-animation-delay: 4079ms;
    animation-delay: 4079ms;
}

.shooting_star:nth-child(15) {
    top: calc(50% - 135px);
    left: calc(50% - 39px);
    -webkit-animation-delay: 4335ms;
    animation-delay: 4335ms;
}

.shooting_star:nth-child(15)::before,
.shooting_star:nth-child(15)::after {
    -webkit-animation-delay: 4335ms;
    animation-delay: 4335ms;
}

.shooting_star:nth-child(16) {
    top: calc(50% - -1px);
    left: calc(50% - 85px);
    -webkit-animation-delay: 1160ms;
    animation-delay: 1160ms;
}

.shooting_star:nth-child(16)::before,
.shooting_star:nth-child(16)::after {
    -webkit-animation-delay: 1160ms;
    animation-delay: 1160ms;
}

.shooting_star:nth-child(17) {
    top: calc(50% - 59px);
    left: calc(50% - 138px);
    -webkit-animation-delay: 830ms;
    animation-delay: 830ms;
}

.shooting_star:nth-child(17)::before,
.shooting_star:nth-child(17)::after {
    -webkit-animation-delay: 830ms;
    animation-delay: 830ms;
}

.shooting_star:nth-child(18) {
    top: calc(50% - -139px);
    left: calc(50% - 5px);
    -webkit-animation-delay: 2093ms;
    animation-delay: 2093ms;
}

.shooting_star:nth-child(18)::before,
.shooting_star:nth-child(18)::after {
    -webkit-animation-delay: 2093ms;
    animation-delay: 2093ms;
}

.shooting_star:nth-child(19) {
    top: calc(50% - -163px);
    left: calc(50% - 33px);
    -webkit-animation-delay: 3895ms;
    animation-delay: 3895ms;
}

.shooting_star:nth-child(19)::before,
.shooting_star:nth-child(19)::after {
    -webkit-animation-delay: 3895ms;
    animation-delay: 3895ms;
}

.shooting_star:nth-child(20) {
    top: calc(50% - -2px);
    left: calc(50% - 187px);
    -webkit-animation-delay: 9415ms;
    animation-delay: 9415ms;
}

.shooting_star:nth-child(20)::before,
.shooting_star:nth-child(20)::after {
    -webkit-animation-delay: 9415ms;
    animation-delay: 9415ms;
}

@-webkit-keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@keyframes tail {
    0% {
        width: 0;
    }

    30% {
        width: 100px;
    }

    100% {
        width: 0;
    }
}

@-webkit-keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 30px;
    }

    100% {
        width: 0;
    }
}

@keyframes shining {
    0% {
        width: 0;
    }

    50% {
        width: 30px;
    }

    100% {
        width: 0;
    }
}

@-webkit-keyframes shooting {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(300px);
    }
}

@keyframes shooting {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(300px);
    }
}

@-webkit-keyframes sky {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}

@keyframes sky {
    0% {
        transform: rotate(45deg);
    }

    100% {
        transform: rotate(405deg);
    }
}