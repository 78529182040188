.contact__container{
    background: var(--color-bg1);
    padding: 4rem;
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 4rem;
    height: 30rem;
    margin: 7rem auto;
    border-radius: 1rem;
}

.contact__aside{    
   background: var(--color-primary); 
   padding: 3rem;
   border-radius: 1rem;
   position: relative;   
   bottom: 10rem;
   box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.3);
   z-index: 1;
}

.aside__image{
    width: 10rem;
    margin-bottom: 1rem;
}

.contact__aside h2{
    text-align: left;
    margin-bottom: 1rem;
}

.contact__aside p{
    font-size: 0.9rem;
    margin-bottom: 2rem;
}

.contact__location {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
}
.contact__map {    
    border: 1px solid var(--color-white);
    border-radius: .5rem;
}

.contact__map h5{
    text-align: center;
    color: var(--color-black);
}

.contact__details li{
    display: flex;
    gap: 1rem;    
}

.contact__socials{
    display: flex;
    gap: 0.8rem;
    margin-top: 3rem;
}

.contact__socials a{
    background: var(--color-bg2);
    padding: 0.5rem;
    border-radius: 50%;
    font-size: 0.9rem;
    transition: var(--transition);
}

.contact__socials a:hover{
    background: transparent;
}

/* FORM SECTION */

.contact__form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-right: 4rem;
}

.form__name{
    display: flex;
    gap: 1.2rem;
}

.contact__form input[type="text"]{
    width: 50% ;
}

input, textarea {
    width: 100%;
    padding: 1rem;
    background: var(--color-bg);
    color: var(--color-white);
}

.contact__form .btn{
    width: max-content;
    margin-top: 1rem;
    cursor: pointer;
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 930px) {
    .contact{
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .contact__container{
        gap: 1.5rem;
        margin-top: 3rem;
        height: auto;
        padding: 1.5rem;
    }

    .contact__aside{
        width: auto;
        padding: 1.5rem;
        bottom: 0;
    }

    .contact__form{
        align-self: center;
        margin-right: 1.5rem;
    }
}

@media screen and (max-width: 430px) {
    .contact__container{
        grid-template-columns: 1fr;
        gap: 3rem;
        margin-top: 0;
        padding: 0;
    }

    .contact__form{
        margin: 0 1.5rem 3rem;
    }

    .form__name {
        flex-direction: column;
    }

    .form__name input[type="text"]{
        width: 100%;
    }
}