/* =========== NAVBAR ========== */
navbar {
  background: linear-gradient(to right, var(--color-bg-variant), var(--color-black));
  width: 100vw;
  height: 5rem;
  position: fixed;
  top: 0;
  border-top: 1px solid var(--color-light);
  border-bottom: 1px solid var(--color-light);
  z-index: 11;
}


/* changes navbar style on scroll using javascript */
.window-scroll {
  /* background: var(--color-bg1);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); */
  display: none;  
}

.navbar__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-image img {
  width: 8rem;
  border-radius: 5px;
  background-color: var(--color-light);

}

/* navbar button {
  display: none;  
} */

.navbar__menu {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar__menu li:not(:last-child) {
  border-right: 1px solid var(--color-light);
  padding-right: 2rem;
}

.navbar__menu li {
  font-size: 0.9rem;
  transition: var(--transition);
}

.navbar__menu li:hover {
  color: var(--color-primary);
  font-size: 15px;
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  /* NAVBAR */
  .navi {
    display: none;    
  }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 820px) {


  .container {
    width: var(--container-width-sm);
  }

  /* NAVBAR */
  /* .navi {
    display: none;
  } */
}