.courses{
  margin-top: -7rem;
  margin-bottom: -5rem;
}

.courses__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 7rem;
}

.course {
  background: var(--color-bg1);
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  border-radius: 10px;
}

.course:hover {
  background: transparent;
  border-color: var(--color-primary);
  color: var(--color-success);
}


.course__heading h1 {
  margin-top: 2rem; 
  margin-bottom: 0;
  color: var(--color-white); 
}

.course__info {
  padding: 2rem;
}

.course__info p {
  margin: 1.2rem;
  font-size: 0.9rem;
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 930px) {
  .courses {
    margin-top: 0.5rem;
  }
 
  .course__heading h1 {    
    font-size: 2rem;    
  }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 825px) {
  .courses {
    width: var(--container-width-md);
  }

  .courses h2 {
    margin-top: -15rem;
    text-align: center; 
  }

  .courses__container {
    grid-template-columns: 1fr;   
    width: 45rem;  
    margin-left: 3rem;   
  }

  .course {
    margin-bottom: 0;
  }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 768px) {
  .courses__container{
   width: 45rem;
   margin-left: 1.5rem;
  }
}

/* ============= MEDIA QUERIES (Phone) ============= */
@media screen and (max-width: 430px) {
  .courses {
    width: var(--container-width-sm);      
    margin-top: -30rem;
  }
  .courses h2 {
    margin-left: 3rem;
  }
  .courses__container{
    margin-top: -3rem;
    margin-left: 2rem;    
    width: 22rem;
  }
  
  .courses h2 {
    margin-top: -17rem;
    font-size: 1.5rem;
  }

  .course__heading h1 {    
    font-size: 1.5rem;    
  }
}