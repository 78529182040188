.undergraduate__container .undergraduate__image {
    margin-bottom: 4rem;
}

.undergraduate__container div:not(:first-child, :nth-child(2)) {
    background: var(--color-bg1);
    padding: 2rem;
    margin: 2rem 0 1rem 0;
    border-radius: 1rem;
}

.undergraduate__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.undergraduate__container div p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.undergraduate__requirements,
.undergraduate__help {
    text-align: center;
    font-size: 1.25rem;
}

/* ========== Media Queries (Tablet) ============ */
@media screen and (max-width: 820px) {
    .undergraduate {
        margin-top: -0.25rem;
    }
}

/* ========== Media Queries (Phone) ============ */
@media screen and (max-width: 430px) {
    .undergraduate__container div:nth-child(2) {
        background: var(--color-bg1);
        padding: 2rem;
        margin: 2rem 0 5rem 0;
        border-radius: 1rem;
    }

    .undergraduate__container div {        
        width: 22rem;
    }

    .undergraduate__container div:not(:first-child) p {
        text-align: left;
        font-size: 1rem;        
    }
    .undergraduate__image{
        margin-top: 1rem;
    }

    .undergraduate__help li {
        text-align: left;
        list-style-type: circle;

    }
}