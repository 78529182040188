.code__container .code__image {    
    margin-bottom: 4rem;    
}

.code__container .code__image img {        
    height: 35rem;
    background-color: var(--color-white);    
}

.code__container div:not(:first-child) {
    background: var(--color-bg1);
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
}
.code__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.code__container div p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.code__container div ul li {
    margin-left: 2rem;
    color: var(--color-success);
}

/* ============ media queries ============= */
@media screen and (max-width: 820px) {
    .code {
        margin-top: -18rem;
    }
    
    .code__container {
        width: var(--container-width-md);        
    }

    .code__container div{        
        width: 45rem;        
    }
    .code__heading img {        
        margin-top: 20rem;        
    }  
}

@media screen and (max-width: 430px) {
    .code__container {
        width: var(--container-width-sm);        
    }

    .code__container div{        
        width: 22rem;          
    }

    .code__container div p {
        text-align: left;
        font-size: 1rem;
    }
}