.privacy__container{
    background: var(--color-white);
    margin-top: 6rem;
    padding: 5rem;
    margin-bottom: 2rem;
    overflow: hidden;
    border-radius: 0.5rem;
}
 
.privacy__container > h2 {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--color-black);    
}
.privacy__container > h4 {
    color: var(--color-danger) ;
} 

.privacy__container > p {
    color: var(--color-black);
    margin-bottom: 10px;
}