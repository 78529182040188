@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@600&family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg-variant: #2c2c6c;  
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-success: #00bf8e;
  --color-warning: #f7c94b;
  --color-danger: #f75842;
  --color-danger-variant: rgba(247, 88, 66, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.7);
  --color-black: #000;
  --color-bg: #1f1f38;
  --color-bg1: #2e3267;
  --color-bg2: #424890;

  --transition: all 400ms ease;
  --border: 5px solid var(--color-primary);

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7; 
}

/* =========== General Style */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,h2,h3,h4,h5 {
  font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

h3{
  color: var(--color-warning);
  margin-bottom: 1rem;
  text-align: center;
}

section h2 {
  text-align: center;
  margin-bottom: 4rem;
}

section{
    margin-top: 8rem;
    /* height: 100vh; */
}

img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.btn {
  display: inline-block;
  background: var(--color-white);
  color: var(--color-black);
  padding: 1rem 2rem;
  border: 1px solid transparent;
  
  font-weight: 500;
  transition: var(--transition);
}

.btn:hover {
  background: transparent;
  color: var(--color-white);
  border-color: var(--color-white);
}

.btn-primary {
  background: var(--color-danger);
  color: var(--color-white);
}

a {
  color: var(--color-white);
}