.ielts__container .ielts__image {
    margin-bottom: 4rem;
}

.ielts__container div:not(:first-child) {
    background: var(--color-bg1);
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
}
.ielts__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.ielts__container div p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.ielts__chose ul div:first-child{
    border: 5px solid var(--color-primary);
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
}

.ielts__chose li {
    margin: 1rem;
    text-align: center;    
    border: 1px solid var(--color-primary);
    padding: 1rem;
    transition: var(--transition);
}

.ielts__chose li:hover {
    background: var(--color-primary);
    color: var(--color-bg-variant);
}

.ielts__heading h1 {
    text-align: center;
}

/* ============ media queries ============= */
@media screen and (max-width: 820px) {
    .ielts {
        margin-top: -18rem;
    }
    .ielts__container {
        width: var(--container-width-md);        
    }

    .ielts__container div{        
        width: 45rem;        
    }
    .ielts__heading {
        width: 30rem;
        margin-top: 20rem;        
    }  
}

@media screen and (max-width: 430px) {
    .ielts__container {
        width: var(--container-width-sm);        
    }

    .ielts__container div{        
        width: 22rem;          
    }

    .ielts__container div p {
        text-align: left;
        font-size: 1rem;
    }
}