.upss {
    padding-top: 3rem;
}
.upss__container div {
    background: var(--color-bg1);
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
}

.upss__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.upss__container p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.upss__container .upss__process li {
    margin-left: 3rem;
    margin: 2rem 0 0 4rem;
}

.upss__container .upss__process p {
    margin-top: 2rem;
}

/* ============ media queries ============= */
@media screen and (max-width: 820px) {
    .upss {
        padding-top: 0;
        margin: 0;
        margin-bottom: 1rem;
    }
    .upss__container {
        width: var(--container-width-md);
        margin-top: -15rem;
        margin-bottom: 20rem;         
        margin-left: 2.5rem;
    }   

    .upss__container div {
        width: 45rem;
    }
}

@media screen and (max-width: 430px) {
    .upss {
        width: var(--container-width-sm);
    }
    .upss__container div{
        width: 22rem;
        margin-left: -.5rem;
    }
    .upss__container div p {
        text-align: left;
        font-size: 1rem;
        line-height: 2rem;        
    }
}