.progressMainWrapper {    
    height: 0.3rem;
    position:fixed ;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
}

.progressMainStyle {
    height: 0.3rem;
    background-color: var(--color-primary);
    width: 1rem;
}
