.omt_header__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
  height: 100%;
  background: linear-gradient(to right, var(--color-bg-variant), var(--color-black));
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.omt_header__left,
.omt_header__right {
  margin-left: 1.5rem;
  padding: 2rem;
}

.omt_header__left h1 {
  font-size: 3rem;
  line-height: 1;
  text-align: left;
  color: var(--color-white);
  font-weight: 700;
  margin-top: 2rem;
}

.omt_header__left p {
  margin: 1rem 0 2.4rem;
}

.omt_header__right img {
  margin-top: -1.5rem;
}

/* ============ media queries (Tablet) ============= */
@media screen and (max-width: 820px) {
  .omt_header__container {
    width: --container-width-md;
    grid-template-columns: 1fr;
    margin-top: -4rem;
  }

  .omt_header__left {
    margin-bottom: -4rem;
    margin-left: 0;
  }

  .omt_header__right {
    margin-left: -0.5rem;
  }

}

/* ============ media queries (Phone) ============= */
@media screen and (max-width: 430px) {
  .omt_header__container {
    width: --container-width-sm;
    width: 23rem;
    margin-left: 1rem;
  }
}