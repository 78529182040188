  .ups_header__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;
    height: 100%;
    background: linear-gradient(to right, var(--color-bg-variant), var(--color-black));
    border-radius: 0.5rem;
    margin-top: -5rem;
  }

  .ups_header__left,
  .ups_header__right {
    margin-left: 1.5rem;
    padding: 2rem;
  }

  .ups_header__left h1 {
    font-size: 3rem;
    line-height: 1;
    text-align: left;
    color: var(--color-white);
    font-weight: 700;
    margin-top: 2rem;
  }

  .ups_header__left p {
    margin: 1rem 0 2.4rem;
  }

  .ups_header__right img {
    margin-top: -1.5rem;
  }

   /* ============= MEDIA QUERIES (TABLETS) ============= */
  @media screen and (max-width: 825px) {
    .ups_header__container {
      width: var(--container-width-md);
      grid-template-columns: 1fr;
      width: 45rem;
      margin-left: 3rem;  
      margin-top: 1rem;       
      margin-bottom: 18rem;
    }

    .ups_header__right {
      margin-left: 0;
    }
  }

  /* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 768px) {
  .ups_header__container{
   width: 45rem;
   margin-left: 1.5rem;
  }
}


  @media screen and (max-width: 430px) {
    .ups_header__container {
      width: var(--container-width-sm);
      width: 22rem;
      margin-left: 2rem;
      gap: 0.5rem;
    }    
  }