header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    width: 112rem;
    /* height: 31rem; */
    border-radius: 1rem;
    overflow: hidden;
}

.slides {
    width: 500%;
    /* height: 31rem; */
    display: flex;
}

.slides input {
    display: none;
}

.slide {
    width: 20%;
    transition: 1s;
}

.slide img {
    width: 112rem;
    height: 31rem;
}

/* for manual navigation */
.navigation-manual {
    position: absolute;
    width: 112rem;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

.manual-btn {
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 1s;
}

.manual-btn:not(:last-child) {
    margin-right: 40px;
}

.manual-btn:hover {
    background: #40D3DC;
}

#radio1:checked~.first {
    margin-left: 0;
}

#radio2:checked~.first {
    margin-left: -20%;
}

#radio3:checked~.first {
    margin-left: -40%;
}

#radio4:checked~.first {
    margin-left: -60%;
}

/* for Auto navigation */
/* Auto navigator radio button will be placed over manual radio button */
.navigation-auto {
    position: absolute;
    display: flex;
    width: 112rem;
    justify-content: center;
    margin-top: 456px;
}

.navigation-auto div {
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
}

.navigation-auto div:not(:last-child) {
    margin-right: 40px;
}

#radio1:checked~.navigation-auto .auto-btn1 {
    background: #40D3DC;
}

#radio2:checked~.navigation-auto .auto-btn2 {
    background: #40D3DC;
}

#radio3:checked~.navigation-auto .auto-btn3 {
    background: #40D3DC;
}

#radio4:checked~.navigation-auto .auto-btn4 {
    background: #40D3DC;
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 930px) {
    header {
        width: var(--container-width-md);
        margin-left: 7rem;
    }

    .slider {
        border-radius: 0;
    }
    .navigation-auto,
    .navigation-manual {
        display: none;
    }

    .slide img {
        width: 44rem;
        height: 15rem;
    }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 825px) {
    header {
        height: 15rem;
        margin: auto;
        margin-top: -10rem;        
    }

    .slider {
        width: 50rem;
        height: 20rem;
        border-radius: 0;
    }

    .slide img {
        width: 48rem;
        height: 13rem;
    }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 740px) {
    header {
        margin-top: 0;
    }
}

    /* ============= MEDIA QUERIES (Phone) ============= */
    @media screen and (max-width: 430px) {
        header {
            width: var(--container-width-sm);
            margin-left: 1.5rem;
            margin-top: -14rem;
        }

        .slide img {
            width: 23rem;
            height: 7rem;
        }
    }