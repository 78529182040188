.about {
    height: auto;
}

.about__container {
    grid-template-columns: 1fr;
}

.about__container h1 {
    text-align: center;
}

.about__image {
    width: 70%;
    height: auto;
    margin: 4rem auto -3rem auto;
    background: white;
    border-radius: 1rem;
    outline: var(--color-primary);
    outline-style: double;
}

.about__image img {
    width: 40rem;
    margin: auto;
}

.description {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    margin-bottom: 2rem;
}

.about__intro,
.about__teaching-programs,
.about__future-plans {
    background: var(--color-bg2);
    padding: 2rem;
    margin: 1rem;
    color: var(--color-white);
    border-radius: 1rem;
}

.description div p {
    line-height: 2.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    /* text-transform:uppercase; */
    text-align: justify;
}

.about__ataglance {
    text-align: center;
    line-height: 3rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.about__ataglance li {
    transition: var(--transition);
}

.about__ataglance li:hover {
    font-size: 1.5rem;
    background-color: var(--color-primary);
    color: var(--color-bg-variant);
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 820px) {
    .about {
        width: --container-width-md;
        margin: 1rem;
    }

    .description {
        grid-template-columns: 1fr;
    }

    .about__image {
        margin: 2rem 1rem -7rem 1rem;
        outline: none;
    }

    .about__image img {
        width: 40.5rem;
        border: 1px solid var(--color-primary);
        border-radius: 1rem;
    }

    .about__ataglance li {
        text-align: left;
        margin-right: 1rem;
        list-style-type: disc;
    }

    .description div p {
        text-align: left;
        line-height: 1.5;
        font-weight: 200;
    }

    .about__ataglance {
        grid-template-columns: 1fr;
        margin-left: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .about__image {
        margin: auto;        
    }
    .about__image img {        
        width: 100%;        
    }

    .description {
        margin-top: 0;
    }
}

@media screen and (max-width: 430px) {
    .about {
        width: --container-width-sm;
    }

    .about__container div {
        width: 20rem;
    }

    .about__image img {
        width: 20rem;
        border: 1px solid var(--color-primary);
        border-radius: 1rem;
    }

    .about__image img:hover {
        width: 40rem;
    }



}