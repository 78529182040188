
  /* CATEGORIES SECTION */

.categories {
  background: var(--color-bg2);
  height: 32rem;  
}

.categories h1 {
  line-height: 1;
  margin-top: 3rem;  
  color: var(--color-warning);
}

.categories__container {
  display: grid;
  grid-template-columns: 40% 60%;
}

.categories__left {
  margin-right: 4rem;
}

.categories__left p {
  margin: 1rem 0 3rem;
}

.categories__right {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

.category {
  background: var(--color-bg);
  padding: 2rem;
  border-radius: 2rem;
  transition: var(--transition);
  margin-bottom: .5rem;
}

.category:hover {
  box-shadow: 0 3rem 3rem rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.category a {
  font-size: 14px;
  color: var(--color-primary);
}
/* for specific card's icon */
.category:nth-child(2) .category__icon {
  background: var(--color-danger);
}
.category:nth-child(3) .category__icon {
  background: var(--color-success);
}
.category:nth-child(4) .category__icon {
  background: var(--color-warning);
}
.category:nth-child(5) .category__icon {
  background: var(--color-success);
}

/* common design for all cards icon */
.category__icon {
  background: var(--color-primary);
  padding: 0.7rem;
  border-radius: 0.9rem;
}

.category h5 {
  margin: 2rem 0 1rem;
}

.category p {
  font-size: 0.85rem;
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 950px) {
  .categories {
    height: 60rem;   
    margin-bottom: 2rem;    
  }
 
  .categories__container {
    grid-template-columns: 1fr;    
  }
  .categories__right {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 820px) {
  .categories {
    height: 75rem;   
    margin-bottom: 2rem;    
  }

.categories__right {
    grid-template-columns: repeat(2, 1fr);
  }
}
/* ============= MEDIA QUERIES (PHONE) ============= */
@media screen and (max-width: 430px) {

  .categories {
    width: --container-width-md;
    height: 129rem;
    margin-left: 1.25rem;
    margin-top: -4rem;
  }

  .categories__right {
    grid-template-columns: 1fr;
  }
}