.faqs {
  background: var(--color-bg1);
  box-shadow: inset 0 0 3rem rgba(0, 0, 0, 0.5);  
  padding-bottom: 1rem;
}

.faqs__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.faqs h2 {
  padding-top: 2rem;
}

.faq {
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;
  height: fit-content;
  background: var(--color-bg2);
  cursor: pointer;
}

.faq h4 {
  font-size: 1.4rem;
  line-height: 2.2;
}

.faq__icon {
  align-self: flex-start;
  font-size: 1.2rem;
}

.faq p {
  margin-top: 0.8rem;
  display: none;
}

.faq.open p {
  display: block;
}

/* ============ media queries ============= */
@media screen and (max-width: 825px) {
  .faqs {
    width: var(--container-width-md);
    margin-top: 1rem;
    width: 45rem;
    margin-left: 3rem;
  }
  .faqs__container {
    grid-template-columns: 1fr;
    margin-top: 0;
  }

}

@media screen and (max-width: 430px) {
  .faqs {
    width: var(--container-width-sm);
    margin-left: 2rem;
    width: 22rem;        
  }

  .faq {
    width: 20rem;
    margin-bottom: 0;
  }
  
  .faq__answer h4 {
    font-size: 1rem;
  }
}