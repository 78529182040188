header {
  position: relative;
  top: 1rem;
  overflow: hidden;
  height: 70vh;
  margin-bottom: 5rem;
}

.header__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5rem;
  height: 100%;
}

.header__left h1,
.categories__left h1 {
  font-size: 3rem;
  line-height: 1;
  text-align: left;
  color: var(--color-white);
  font-weight: 700;
}

.header__left p {
  margin: 1rem 0 2.4rem;
}


/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 930px) {
  .header__container {
    height: 80vh;
    padding-top: 1rem;
   
  }

  .header__left h1 {
    font-size: 2rem;
  }
}

/* ============= MEDIA QUERIES (TABLETS) ============= */
@media screen and (max-width: 820px) {
  header {
    width: var(--container-width-md);
  }

  .header__container {
    margin-top: 6rem;
  }
}

/* ============= MEDIA QUERIES (Landscape Phone) ============= */
@media screen and (max-width: 768px) {
  .header__container {
    margin-top: 2rem;
  }

  .header__left h1{
    font-size: 1.75rem;
  }
  .header__left a {
    margin-top: -2rem;
  }
}

/* ============= MEDIA QUERIES (Phone) ============= */
@media screen and (max-width: 430px) {
  header {
    width: var(--container-width-sm);
    height: 50rem;
  }

  .header__container {
    grid-template-columns: 1fr;
    padding-top: 9rem;
  }

  .header__left p {
    margin-bottom: 1.5rem;
  }

  .header__right {
    margin-top: -5rem;
    overflow: hidden;
  }
}