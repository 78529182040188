.doctorate__container .doctorate__image {
    margin-bottom: 4rem;
}

.doctorate__container div:not(:first-child) {
    padding: 2rem;
    margin: 2rem 0 5rem 0;
    border-radius: 1rem;
    background: var(--color-bg1);
}

.doctorate__container h2 {
    color: var(--color-warning);
    text-transform: capitalize;
}

.doctorate__container div p {
    font-size: 1.25rem;
    text-align: justify;
    margin-bottom: 1rem;
    line-height: 3rem;
    transition: var(--transition);
}

.doctorate__container div p strong {
    color: var(--color-success);
}

.doctorate__container div li {
    margin-left: 3rem;
    list-style: circle;
}

/* ========== Media Queries (Tablet) ============ */
@media screen and (max-width: 820px) {
    .doctorate {
        margin-top: -0.25rem;
    }
}

/* ========== Media Queries (Phone) ============ */
@media screen and (max-width: 430px) {
    .doctorate__container div {
        width: 22rem;
    }

    .doctorate__container div:not(:first-child) p {
        text-align: left;
        font-size: 1rem;
    }

    .doctorate__image {
        margin-top: 1rem;
    }

    .doctorate__help li {
        text-align: left;
        list-style-type: circle;

    }
}